<template>
  <v-container
    class="pa-0 ma-0"
    style="background-color: white; height: 100%"
  >
    <v-card
      v-if="client"
      flat
      height="100%"
      color="white"
    >
      <v-toolbar
        flat
        class="general-form-toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$router.back()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('profile|individual_profile') }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-model="form.given_names"
          :label="$t('clients|first_name')"
          :readonly="client.realClient"
          outlined
          dense
          class="mt-2"
        />
        <v-text-field
          v-model="form.surname"
          :label="$t('clients|surname')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-autocomplete
          v-model="form.nationality"
          :items="CountryList"
          :filter="countryFilter"
          item-text="nationality"
          item-value="alpha_2_code"
          :label="$t('clients|nationality')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-text-field
          v-model="form.place_of_birth"
          :label="$t('clients|place_of_birth')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-menu
          v-model="dateOfBirthMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date_of_birth"
              :label="$t('clients|date_of_birth') + ' ' + $t('common|in_format')"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-if="!client.realClient"
            v-model="date"
            @input="dateOfBirthMenu = false"
          />
        </v-menu>
        <v-text-field
          v-model="form.address"
          :label="$t('clients|address')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-text-field
          v-model="form.city"
          :label="$t('clients|city')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-text-field
          v-model="form.postal_code"
          :label="$t('clients|postal_code')"
          :readonly="client.realClient"
          outlined
          dense
        />
        <v-text-field
          v-model="form.email"
          :label="$t('common|email')"
          :readonly="client.realClient"
          outlined
          dense
        />
      </v-card-text>
      <v-toolbar
        flat
        dense
        style="position: sticky; bottom: 0;"
      >
        <v-spacer />
        <v-btn
          v-if="!client.realClient"
          color="primary"
          class="mb-2 mr-2"
          rounded
          dense
          small
          :loading="clientsLoading || accountLoading"
          min-width="150"
          @click="submit({
            form: form,
            files: client.documents
          })"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-container>
</template>

<script>
import ClientActions from '../../Clients/ClientMixins/ClientActions'
import clientMixin from '../../Clients/ClientMixins/clientMixin'
import CountryList from '@/enums/CountryList.json'

const formData = {
  given_names: null,
  surname: null,
  email: null,
  nationality: null,
  place_of_birth: null,
  date_of_birth: null,
  address: null
}

export default {
  mixins: [ClientActions, clientMixin],
  data () {
    return {
      dateOfBirthMenu: false,
      date: null,
      CountryList,
      loading: false,
      error: null,
      form: { ...formData },
      actualClient: null
    }
  },
  watch: {
    date (val) {
      if (val) {
        this.formatDate(val)
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length) {
      for (const key in this.$route.params) {
        if (Object.hasOwnProperty.call(this.$route.params, key)) {
          const obj = this.$route.params[key]
          if (key && key === 'client') {
            this.actualClient = obj
            this.form = obj.clientData
          }
        }
      }
    } else {
      this.actualClient = this.clients.find(c => c._id === this.$route.params._id) || this.companyClients.find(c => c._id === this.$route.params._id) || this.groupClients.find(c => c._id === this.$route.params._id)
      if (!this.actualClient) this.$router.push('/clients')
    }
  },
  methods: {
    formatDate (date) {
      const [year, month, day] = date.split('-')
      this.form.date_of_birth = `${day}/${month}/${year}`
    },
    countryFilter (item, queryText, itemText) {
      const textOne = item.nationality.toLowerCase()
      const textTwo = item.en_short_name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form-toolbar {
  position: sticky;
  top: 48px;
  z-index: 1;
}
</style>
